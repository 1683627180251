import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";
import FAQ from "components/Partials/FAQ";
import React from "react";

const Index = () => {
  return (
    <>
      <FAQ />
      <UserBottomBarMobile />
    </>
  );
};

export default Index;
