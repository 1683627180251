import { Link } from "react-router-dom";

import { DropdownMenu, DropdownItem } from "reactstrap";

const Notifikasi = (props) => {
  return (
    <DropdownMenu className="dropdown-menu-arrow pt-3 mt-2 ml-4" right>
      {Array.isArray(props.notifications) ? (
        props.notifications.map((item, index) => (
          <>
            <DropdownItem className="d-flex flex-row align-items-center px-4">
              <i className="ni ni-check-bold" />
              <div className="d-flex flex-column">
                <span>Selamat kamu berhasil mendaftar event</span>
                <small>Kamu berhasil mendaftar pada event E-sport 2022</small>
              </div>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              className="d-flex flex-row align-items-center justify-content-center px-4"
              to="/notifikasi"
              tag={Link}
            >
              <span className="text-center">All notification</span>
            </DropdownItem>
          </>
        ))
      ) : (
        <DropdownItem className="d-flex flex-row align-items-center px-4">
          {/* <i className="ni ni-check-bold" /> */}
          <div className="d-flex flex-column">
            <span>Not Found Notifications</span>
            {/* <small>Kamu berhasil mendaftar pada event E-sport 2022</small> */}
          </div>
        </DropdownItem>
      )}
    </DropdownMenu>
  );
};

export default Notifikasi;
