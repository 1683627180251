import { useEffect, useState } from "react";
import Sertifikat from "components/Items/Sertifikat";
import {
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import { showToast } from "components/Partials/Toast";
import API from "utils/API";
import NoDataImage from "components/Partials/NoDataImage";
// import Breadcumbs from "components/Partials/Breadcumbs";
import Loading from "components/Partials/Loading";
import { useMediaQuery } from "react-responsive";
import { Redirect } from "react-router-dom";
import UserBottomBarMobile from "components/Footers/UserBottomBarMobile";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Index = () => {
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(true);

  const useAuth = UseAuth();
  const router = useHistory();
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const [page, setPage] = useState(1);

  const pageRange = 2;
  const halfRange = Math.floor(pageRange / 2);
  let start = Math.max(page - halfRange, 1);
  let end = Math.min(start + pageRange - 1);
  start = Math.max(end - pageRange + 1, 1);
  const pageNumbers = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  useEffect(() => {
    if (useAuth.auth) getUserCertificates();
  }, []);

  // Tinggal Hit Api Sesuai Pagination
  const getUserCertificates = () => {
    setLoading(true);
    API.get("certificates/getUserCertificates", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data)
        setLoading(false);
        setEvent(res.data.data);
      })
      .catch((err) => {
        // console.log(err.response.data)
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  if (!useAuth.auth) return <Redirect from="*" to="/auth" />;

  if (loading) return <Loading />;

  // if (!loading && event.length === 0)
  //   return <NoDataImage type="certificate" message="Belum ada event diikuti" />;

  return (
    // {/* <Breadcumbs data={['Ruang-Sertifikat']} /> */}
    <>
      {isSmallScreen ? (
        !loading && event.length === 0 ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              padding: "20px",
            }}
          >
            {/* <div> */}
            <button
              outline
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                flexDirection: "row",
                fontSize: "13px",
                gap: "5px",
                color: "black",
                border: "none",
                background: "none",
                cursor: "pointer",
                padding: "10px",
              }}
              onClick={() => router.push("/")}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z"
                  fill="#404040"
                />
              </svg>
              Ruang Sertifikat
            </button>
            <NoDataImage
              type="certificate"
              message="Ups, Sertifikat event ini tidak dapat ditemukan"
              pt=""
            />
            <UserBottomBarMobile />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100vh",
              padding: "20px",
            }}
          >
            {/* <div> */}
            <button
              outline
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                flexDirection: "row",
                fontSize: "13px",
                gap: "5px",
                color: "black",
                border: "none",
                background: "none",
                cursor: "pointer",
                padding: "10px",
              }}
              onClick={() => router.push("/")}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z"
                  fill="#404040"
                />
              </svg>
              Ruang Sertifikat
            </button>
            {/* </div> */}
            <Row>
              {event.map((item) => (
                <Sertifikat item={item} xl="6" lg="6" md="6" key={item._id} />
              ))}
            </Row>

            {event.length > 5 && (
              <Pagination listClassName="justify-content-center">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page - 1);
                    }}
                    tabIndex="-1"
                  >
                    <i className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>

                {pageNumbers.map((pageNumber) => (
                  <PaginationItem
                    key={pageNumber}
                    className={page === pageNumber ? "active" : ""}
                  >
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(pageNumber);
                      }}
                    >
                      {pageNumber}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page + 1);
                    }}
                  >
                    <i className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            )}

            <UserBottomBarMobile />
          </div>
        )
      ) : !loading && event.length === 0 ? (
        <Container className={`pt-${isSmallScreen ? "3" : "8"}`} fluid>
          <NoDataImage type="certificate" message="Belum ada event diikuti" />
        </Container>
      ) : (
        <Container className={`pt-${isSmallScreen ? "3" : "8"}`} fluid>
          <Row>
            {event.map((item) => (
              <Sertifikat item={item} xl="6" lg="6" md="6" key={item._id} />
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default Index;
