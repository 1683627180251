import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container,
  Progress,
  Spinner,
  ButtonGroup,
  Badge,
  Modal,
} from "reactstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import Cities from "components/Partials/Cities";
import EventBenefits from "components/Partials/EventBenefits";
import EventContexts from "components/Partials/EventContexts";
import EventPreconditions from "components/Partials/EventPreconditions";
import EventTypes from "components/Partials/EventTypes";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import EventLanguages from "components/Partials/EventLanguages";

import Map, { Marker } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import Event from "components/Items/Event";
import { Redirect, useHistory } from "react-router-dom";
import SuccessImage from "components/Partials/SuccessImage";
import { CertificatePreview } from "components/Partials/CertificatePreview";
import { showToast } from "components/Partials/Toast";
import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";
import PhoneInput from "react-phone-number-input";
import logo from "../../assets/img/brand/brand-color.png";
import QRCode from "qrcode";
// import mapboxgl from "mapbox-gl";
// // eslint-disable-next-line import/no-webpack-loader-syntax
// import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
// mapboxgl.workerClass = MapboxWorker;

const StepI = ({ props }) => {
  const [loading, setLoading] = useState(false);

  const useAuth = UseAuth();

  const updatePoster = (image) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", image[0]);

    API.put("events/editImageEvent/" + props.match.params.slug, formData, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data)
        props.setImage(res.data.data.image.url);
        setLoading(false);
        showToast(res.data.message, res.data.status.toLowerCase());
      })
      .catch((err) => {
        // console.log(err.response)
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const onChangePoster = (event) => {
    if (props.match.params.slug) updatePoster(event.target.files);
    else props.setImage(event.target.files);
  };

  return (
    <>
      <Row>
        <Col md="12" className="mt-3">
          <FormGroup>
            <label className="text-sm">
              Jenis Event<label className="text-danger">*</label>
            </label>
            <EventTypes
              eventTypeId={props.eventTypeId}
              setEventTypeId={props.setEventTypeId}
            />
          </FormGroup>
        </Col>

        <Col md="12">
          <label className="text-sm">Gambar Poster Event</label>
          <Row>
            <Col md="4">
              <img
                className="img-fluid w-100 h-100 rounded-lg"
                src={
                  typeof props.image === "object"
                    ? URL.createObjectURL(props.image[0])
                    : props.image
                }
                alt="hievents"
              />
            </Col>

            <Col md="4">
              <div className="d-flex flex-column p-3">
                <span className="font-weight-bold">Besar file maksimum</span>
                <span>10MB</span>

                <span className="font-weight-bold mt-2">Type file</span>
                <span>PNG, JPG, JPEG</span>
              </div>

              <label
                htmlFor="browse-image"
                className="btn btn-sm p-2 btn-primary mt-4"
              >
                {loading ? <Spinner size="sm" /> : null} Browse File
              </label>
              <input
                hidden
                type="file"
                accept="image/png, image/jpeg"
                id="browse-image"
                className="form-control"
                onChange={(event) => onChangePoster(event)}
              />
            </Col>
          </Row>
        </Col>

        <Col md="12" className="mt-3">
          <FormGroup>
            <label className="text-sm">
              Judul Event<label className="text-danger">*</label>
            </label>
            <Input
              type="text"
              placeholder="Ex. Peran Guru dalam Perkembangan Era Digital"
              value={props.title}
              onChange={(event) => props.setTitle(event.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

const StepII = ({ props }) => {
  return (
    <>
      <Row>
        <Col md="12" className="mt-3">
          <FormGroup>
            <label className="text-sm">Konteks Event</label>
            <EventContexts
              eventContextId={props.eventContextId}
              setEventContextId={props.setEventContextId}
            />
          </FormGroup>
        </Col>
        <Col md="12" className="mt--3">
          <FormGroup>
            <label className="text-sm">
              Biaya (Untuk event berbayar akan tersedia dalam waktu dekat)
            </label>
            <div className="custom-control custom-checkbox mb-1">
              <input
                className="custom-control-input"
                id="paidStatusCheck"
                type="checkbox"
                disabled
                defaultChecked={true}
                value={props.paidStatus === "free" ? true : false}
                onChange={(event) =>
                  props.setPaidStatus(
                    props.paidStatus === "free" ? "paid" : "free"
                  )
                }
              />
              <label className="custom-control-label" htmlFor="paidStatusCheck">
                Gratis
              </label>
            </div>
            {props.paidStatus === "paid" ? (
              <Input
                type="number"
                placeholder="25000"
                value={props.cost}
                onChange={(event) => props.setCost(event.target.value)}
              />
            ) : null}
          </FormGroup>
        </Col>
        <Col md="12" className="mt--3">
          <FormGroup>
            <label className="text-sm">Jumlah Maksimum Peserta</label>
            <div className="custom-control custom-checkbox mb-1">
              <input
                className="custom-control-input"
                id="maksimumPesertaCheck"
                type="checkbox"
                defaultChecked={true}
                value={props.limitedTicketStatus === "unlimited" ? true : false}
                onChange={(event) =>
                  props.setLimitedTicketStatus(
                    props.limitedTicketStatus === "unlimited"
                      ? "limited"
                      : "unlimited"
                  )
                }
              />
              <label
                className="custom-control-label"
                htmlFor="maksimumPesertaCheck"
              >
                Tidak Terbatas
              </label>
            </div>

            {props.limitedTicketStatus === "limited" ? (
              <Input
                className="mt-2"
                type="number"
                placeholder="100"
                value={props.maximumTicket}
                onChange={(event) => props.setMaximumTicket(event.target.value)}
              />
            ) : null}
          </FormGroup>
        </Col>
        <Col md="12" className="mt--3">
          <FormGroup>
            <label className="text-sm">Tentang Event</label>
            {/* <Input
                            rows="3"
                            type="textarea"
                            value={props.description}
                            onChange={(event) => props.setDescription(event.target.value)}
                        /> */}
            <Editor
              apiKey={process.env.REACT_APP_tinyApiKey}
              value={props.description}
              onEditorChange={(value) => props.setDescription(value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

const StepIII = ({ props }) => {
  return (
    <>
      <Row>
        <Col md="12" className="mt-3">
          <label className="text-sm">Benefit untuk Peserta</label>
          <EventBenefits
            benefitIds={props.benefitIds}
            setBenefitIds={props.setBenefitIds}
          />
        </Col>
        <Col md="12" className="mt-3">
          <label className="text-sm">Prasyarat</label>
          <EventPreconditions
            preconditionIds={props.preconditionIds}
            setPreconditionIds={props.setPreconditionIds}
          />
        </Col>
        <Col md="12" className="mt-3">
          <label className="text-sm">Bahasa</label>
          <EventLanguages
            languageIds={props.languageIds}
            setLanguageIds={props.setLanguageIds}
          />
        </Col>
        <Col md="12" className="mt-3">
          <label className="text-sm">Privasi Event</label>
          <div className="custom-control custom-checkbox mb-1">
            <input
              className="custom-control-input"
              id="privasiCheck"
              type="checkbox"
              defaultChecked={props.privateEvent}
              value={props.privateEvent}
              onChange={(event) => props.setPrivateEvent(!props.privateEvent)}
            />
            <label className="custom-control-label" htmlFor="privasiCheck">
              Event ini Tertutup
            </label>
          </div>
          {props.privateEvent ? (
            <label>
              *Kode event didapat setelah event berhasil dibuat. Silahkan
              bagikan kode private agar peserta event dapat mendaftar event
              tertutup
            </label>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

const StepIV = ({ props }) => {
  const mapRef = useRef();
  const geocoderContainerRef = useRef();
  const [viewState, setViewState] = useState({
    longitude: props.longitude ? props.longitude : 106.8341961,
    latitude: props.latitude ? props.latitude : -6.182688,
    zoom: 10,
  });
  const [marker, setMarker] = useState(
    props.longitude && props.latitude
      ? {
          longitude: props.longitude ? props.longitude : 106.8341961,
          latitude: props.latitude ? props.latitude : -6.182688,
        }
      : null
  );
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
  const handleViewportChange = useCallback((newViewport) => {
    setViewState(newViewport);
  }, []);
  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };
      setMarker({
        longitude: newViewport.longitude,
        latitude: newViewport.latitude,
      });
      props.setLongitude(newViewport.longitude);
      props.setLatitude(newViewport.latitude);

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );
  const handleClick = ({ lngLat }) => {
    setMarker({ longitude: lngLat[0], latitude: lngLat[1] });
    props.setLongitude(lngLat[0]);
    props.setLatitude(lngLat[1]);
  };

  return (
    <>
      <Row>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="8">
              <label className="text-sm">
                Apakah event diadakan secara offline/online ?
              </label>
            </Col>

            <Col md="4">
              <ButtonGroup>
                <Button
                  className="btn-sm"
                  color="primary"
                  outline
                  onClick={() => (
                    props.setEventMedia("online"),
                    setMarker(null),
                    props.setLongitude(null),
                    props.setLatitude(null)
                  )}
                  active={props.eventMedia === "online"}
                >
                  Online
                </Button>
                <Button
                  className="btn-sm"
                  color="primary"
                  outline
                  onClick={() => (
                    props.setEventMedia("offline"), props.setAccessEventLink("")
                  )}
                  active={props.eventMedia === "offline"}
                >
                  Offline
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
        {props.eventMedia === "online" ? (
          <>
            <Col md="12" className="mt-2">
              <FormGroup>
                <label className="text-sm">Link Akses Event</label>
                <Input
                  type="text"
                  placeholder="Link Zoom / Gmeet / lainnya"
                  value={props.accessEventLink}
                  onChange={(event) =>
                    props.setAccessEventLink(event.target.value)
                  }
                />
              </FormGroup>
            </Col>
          </>
        ) : (
          <>
            <Col md="12" className="mt-2">
              <Map
                ref={mapRef}
                {...viewState}
                // onMove={evt => setViewState(evt.viewState)}
                // initialViewState={viewport}
                onClick={handleClick}
                onViewportChange={handleViewportChange}
                dragRotate={false}
                doubleClickZoom={false}
                touchZoom={false}
                touchRotate={false}
                width="100%"
                height={400}
                // style={{ width: '100%', height: 400 }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                mapboxApiAccessToken={MAPBOX_TOKEN}
                // mapboxAccessToken={MAPBOX_TOKEN}
              >
                <Geocoder
                  mapRef={mapRef}
                  containerRef={geocoderContainerRef}
                  onViewportChange={handleGeocoderViewportChange}
                  mapboxApiAccessToken={MAPBOX_TOKEN}
                  position="top-left"
                  countries="id"
                  limit={3}
                  marker={false}
                />
                {marker ? (
                  <Marker
                    {...marker}
                    draggable={true}
                    anchor="center"
                    onDragEnd={handleClick}
                  >
                    <div className="ml--1 mt--3">
                      <i
                        className="fa fa-map-marker ml--2 fa-2x"
                        style={{ color: "#FF0000" }}
                      />
                    </div>

                    {/* <div className="d-flex flex-column align-items-center">
                                            <div>{marker.latitude}, {marker.longitude}</div>
                                            <i className="fa fa-map-marker fa-lg mt-1" />
                                        </div> */}
                  </Marker>
                ) : null}
              </Map>

              <div
                ref={geocoderContainerRef}
                style={{ position: "absolute", top: 15, left: 27 }}
              />
            </Col>
            <Col md="12" className="mt-3">
              <FormGroup>
                <label className="text-sm">Kota</label>
                <Cities
                  cityId={props.cityId}
                  setCityId={props.setCityId}
                  placeholder="Kota event"
                />
              </FormGroup>
            </Col>
            <Col md="12" className="mt--3">
              <FormGroup>
                <Input
                  rows="2"
                  type="textarea"
                  placeholder="Keterangan alamat event"
                  value={props.address}
                  onChange={(event) => props.setAddress(event.target.value)}
                />
              </FormGroup>
            </Col>
          </>
        )}

        <Col md="12" className="mt--3">
          <FormGroup>
            <label className="text-sm">
              Link Group WhatsApp / Telegram / Lainnya
            </label>
            <Input
              type="text"
              placeholder="Link Group WhatsApp / Telegram / Lainnya"
              value={props.groupLink}
              onChange={(event) => props.setGroupLink(event.target.value)}
            />
          </FormGroup>
        </Col>

        <Col md="12" className="mt--3">
          <FormGroup>
            <label className="text-sm">Tanggal Mulai Event</label>
            <DatePicker
              className="form-control w-100"
              dateFormat="dd MMM yyyy HH:mm"
              showTimeSelect
              minDate={new Date()}
              placeholderText="Pilih tanggal mulai event"
              selected={props.startDate}
              startDate={props.startDate}
              onChange={(date) => props.setStartDate(date)}
              // withPortal
            />
          </FormGroup>
        </Col>

        {props.startDate ? (
          <Col md="12" className="mt--3">
            <FormGroup>
              <label className="text-sm">Tanggal Berakhir Event</label>
              <DatePicker
                className="form-control w-100"
                dateFormat="dd MMM yyyy HH:mm"
                showTimeSelect
                minDate={props.startDate}
                placeholderText="Pilih tanggal berakhir event"
                selected={props.endDate}
                onChange={(date) => props.setEndDate(date)}
                // withPortal
              />
            </FormGroup>
          </Col>
        ) : null}

        {props.startDate && props.endDate ? (
          <>
            <Col md="12" className="mt--3">
              <FormGroup>
                <label className="text-sm">Deadline Pendaftaran</label>
                <DatePicker
                  className="form-control w-100"
                  dateFormat="dd MMM yyyy HH:mm"
                  showTimeSelect
                  minDate={new Date()}
                  maxDate={props.startDate}
                  placeholderText="Pilih tanggal deadline pendaftaran"
                  selected={props.deadlineRegistration}
                  onChange={(date) => {
                    props.setDeadlineRegistration(date);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="12" className="mt--3">
              <FormGroup>
                <label className="text-sm">Deadline Absensi</label>
                <DatePicker
                  className="form-control w-100"
                  dateFormat="dd MMM yyyy HH:mm"
                  showTimeSelect
                  minDate={props.startDate}
                  maxDate={props.endDate}
                  placeholderText="Pilih tanggal deadline absensi"
                  selected={props.deadlineAttendance}
                  onChange={(date) => {
                    props.setDeadlineAttendance(date);
                  }}
                />
              </FormGroup>
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
};

const StepV = ({ props }) => {
  const [loading, setLoading] = useState(false);
  const [qrcode, setQrCode] = useState("");

  useEffect(() => {
    const linkCode = process.env.REACT_APP_currentURL + "/sertifikat/hievents";
    QRCode.toDataURL(linkCode)
      .then((url) => {
        setQrCode(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const useAuth = UseAuth();

  const updateCertificate = (image) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("certificateFile", image[0]);

    API.put(
      "events/editCertificateEvent/" + props.match.params.slug,
      formData,
      {
        headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
      }
    )
      .then((res) => {
        // console.log(res.data)
        props.setCertificateFile(res.data.data.certificateFile.url);
        setLoading(false);
        showToast(res.data.message, res.data.status.toLowerCase());
      })
      .catch((err) => {
        // console.log(err.response)
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const onChangeCertificate = (event) => {
    if (props.match.params.slug) updateCertificate(event.target.files);
    else props.setCertificateFile(event.target.files);
  };

  return (
    <>
      <Row>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="8">
              <label className="text-sm">Apakah Event Bersertifikat ?</label>
            </Col>

            <Col md="4">
              <ButtonGroup>
                <Button
                  className="btn-sm"
                  color="primary"
                  outline
                  onClick={() => props.setCertifiedEvent(true)}
                  active={props.certifiedEvent === true}
                >
                  Ya
                </Button>
                <Button
                  className="btn-sm"
                  color="primary"
                  outline
                  onClick={() => props.setCertifiedEvent(false)}
                  active={props.certifiedEvent === false}
                >
                  Tidak
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Col>

        {props.certifiedEvent ? (
          <>
            <Col md="12" className="mt-3">
              <Row>
                <Col md="8">
                  <label className="text-sm">
                    Apakah Anda Memiliki Template Sertifikat ?
                  </label>
                </Col>

                <Col md="4">
                  <ButtonGroup>
                    <Button
                      className="btn-sm"
                      color="primary"
                      outline
                      onClick={() => props.setHaveCertificateTemplate(true)}
                      active={props.haveCertificateTemplate === true}
                    >
                      Ya
                    </Button>
                    <Button
                      className="btn-sm"
                      color="primary"
                      outline
                      onClick={() => props.setHaveCertificateTemplate(false)}
                      active={props.haveCertificateTemplate === false}
                    >
                      Tidak
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Col>

            {props.haveCertificateTemplate ? (
              <>
                <Col md="12" className="mt-3 mb-2">
                  <Row>
                    <Col md="8">
                      <div className="d-flex flex-column">
                        <label className="text-sm">
                          Upload Template Sertifikat
                        </label>
                        <label className="text-xs mt--2">
                          *file image dimensi 800x585 pixel atau kelipatan
                        </label>
                      </div>
                    </Col>

                    <Col md="4" className="d-flex flex-row align-items-center">
                      <label
                        htmlFor="browse-sertificate"
                        className="btn btn-sm p-2 btn-primary mt-2"
                      >
                        {loading ? <Spinner size="sm" /> : null} Upload
                      </label>
                      <input
                        hidden
                        type="file"
                        id="browse-sertificate"
                        className="form-control"
                        onChange={(event) => onChangeCertificate(event)}
                      />

                      <button
                        onClick={(event) => CertificatePreview(event)}
                        className="btn btn-sm p-2 btn-warning"
                      >
                        Unduh Preview
                      </button>
                    </Col>
                  </Row>
                </Col>

                <Col id="certificate" md="12" className="p-0">
                  <img
                    className="w-100 h-100 rounded-lg"
                    style={{ objectFit: "cover" }}
                    src={
                      typeof props.certificateFile === "object"
                        ? URL.createObjectURL(props.certificateFile[0])
                        : props.certificateFile
                    }
                    alt="hievents"
                    crossOrigin="true"
                  />
                  <h1
                    className="w-100 text-center display-3 certificate-name"
                    style={{ fontWeight: "bolder" }}
                  >
                    NAMA PESERTA
                  </h1>
                  <div className="w-100 text-center certificate-logo">
                    <img
                      className="certificate-logo-img"
                      src={logo}
                      alt="hievents"
                    />
                  </div>
                  <div className="w-100 certificate-code">
                    <img src={qrcode} className="qrcode"></img>
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col md="12" className="mt-2">
                  <FormGroup>
                    <label className="text-sm">
                      Silahkan masukkan nomor whatsapp
                    </label>
                    <p className="text-xs mt--2">
                      *agar admin sertifikat HiEvents dapat membantu anda scara
                      personal 1-1 dan profesional
                    </p>
                    <PhoneInput
                      className="form-control"
                      defaultCountry="ID"
                      international
                      countryCallingCodeEditable={false}
                      placeholder="Nomor Whatsapp"
                      value={props.contact}
                      onChange={(val) => props.setContact(val)}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
          </>
        ) : null}
      </Row>
    </>
  );
};

const Index = ({ match, EO }) => {
  const [eventTypeId, setEventTypeId] = useState("");
  const [image, setImage] = useState(
    require("../../assets/img/theme/upload.svg").default
  );
  const [title, setTitle] = useState("");

  const [eventContextId, setEventContextId] = useState("");
  const [cost, setCost] = useState("");
  const [paidStatus, setPaidStatus] = useState("free");
  const [limitedTicketStatus, setLimitedTicketStatus] = useState("unlimited");
  const [maximumTicket, setMaximumTicket] = useState("");
  const [description, setDescription] = useState("");

  const [benefitIds, setBenefitIds] = useState([]);
  const [preconditionIds, setPreconditionIds] = useState([]);
  const [languageIds, setLanguageIds] = useState([]);
  const [privateEvent, setPrivateEvent] = useState(false);

  const [eventMedia, setEventMedia] = useState("online");
  const [accessEventLink, setAccessEventLink] = useState("");
  const [groupLink, setGroupLink] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [deadlineRegistration, setDeadlineRegistration] = useState(null);
  const [deadlineAttendance, setDeadlineAttendance] = useState(null);
  const [cityId, setCityId] = useState("");
  const [address, setAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");

  const [certifiedEvent, setCertifiedEvent] = useState(false);
  const [haveCertificateTemplate, setHaveCertificateTemplate] = useState(false);
  const [certificateFile, setCertificateFile] = useState(
    process.env.REACT_APP_urlCertificate
  );
  const [contact, setContact] = useState("");

  const [event, setEvent] = useState({});
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const useAuth = UseAuth();
  const navigate = useHistory();

  useEffect(() => {
    if (match.params.slug) getEvent(match.params.slug);
  }, []);

  const onCreateEvent = (status) => {
    setLoading(true);
    if (match.params.slug) {
      const data = {
        eventTypeId,
        title,
        eventContextId,
        paidStatus,
        cost,
        limitedTicketStatus,
        maximumTicket,
        description,
        benefitIds,
        preconditionIds,
        languageIds,
        privateEvent,
        location: eventMedia,
        accessEventLink,
        groupLink,
        startDate: moment(startDate).format("YYYY-MM-DD HH:mm:00"),
        endDate: moment(endDate).format("YYYY-MM-DD HH:mm:00"),
        deadlineRegistration: moment(deadlineRegistration).format(
          "YYYY-MM-DD HH:mm:00"
        ),
        deadlineAttendance: moment(deadlineAttendance).format(
          "YYYY-MM-DD HH:mm:00"
        ),
        address,
        longitude,
        latitude,
        certifiedEvent,
        haveCertificateTemplate,
        contact,
        status,
      };
      if (cityId !== "") data.cityId = cityId;

      API.put("events/editEvent/" + match.params.slug, data, {
        headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
      })
        .then((res) => {
          // console.log(res.data)
          setLoading(false);
          getEvent(res.data.data.slug, true);
          showToast(res.data.message, res.data.status.toLowerCase());
          setStep(6);
        })
        .catch((err) => {
          console.log(err.response.data);
          setLoading(false);
          showToast(
            err.response.data.message,
            err.response.data.status.toLowerCase()
          );
        });
    } else {
      var formData = new FormData();
      formData.append("eventTypeId", eventTypeId);
      formData.append("image", typeof image[0] === "object" ? image[0] : "");
      formData.append("title", title);

      formData.append("eventContextId", eventContextId);
      formData.append("cost", cost);
      formData.append("paidStatus", paidStatus);
      formData.append("limitedTicketStatus", limitedTicketStatus);
      formData.append("maximumTicket", maximumTicket);
      formData.append("description", description);

      if (benefitIds.length > 0)
        benefitIds.forEach((element) => formData.append("benefitIds", element));
      if (preconditionIds.length > 0)
        preconditionIds.forEach((element) =>
          formData.append("preconditionIds", element)
        );
      if (languageIds.length > 0)
        languageIds.forEach((element) =>
          formData.append("languageIds", element)
        );
      formData.append("privateEvent", privateEvent);

      formData.append("accessEventLink", accessEventLink);
      formData.append("groupLink", groupLink);
      formData.append("location", eventMedia);
      formData.append(
        "startDate",
        moment(startDate).format("YYYY-MM-DD HH:mm:00")
      );
      formData.append("endDate", moment(endDate).format("YYYY-MM-DD HH:mm:00"));
      formData.append(
        "deadlineRegistration",
        moment(deadlineRegistration).format("YYYY-MM-DD HH:mm:00")
      );
      formData.append(
        "deadlineAttendance",
        moment(deadlineAttendance).format("YYYY-MM-DD HH:mm:00")
      );
      if (cityId !== "") formData.append("cityId", cityId);
      formData.append("address", address);
      formData.append("longitude", longitude);
      formData.append("latitude", latitude);

      formData.append("certifiedEvent", certifiedEvent);
      formData.append("haveCertificateTemplate", haveCertificateTemplate);
      if (haveCertificateTemplate)
        formData.append(
          "certificateFile",
          typeof certificateFile[0] === "object" ? certificateFile[0] : ""
        );
      formData.append("contact", contact);

      formData.append("status", status);

      // for (var pair of formData.entries()) {
      //     console.log(pair[0] + ', ' + pair[1]);
      // }

      API.post("events/createEvent", formData, {
        headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
      })
        .then((res) => {
          // console.log(res.data)
          setLoading(false);
          getEvent(res.data.data.slug);
          showToast(res.data.message, res.data.status.toLowerCase());
          setStep(6);
        })
        .catch((err) => {
          console.log(err.response.data);
          setLoading(false);
          showToast(
            err.response.data.message,
            err.response.data.status.toLowerCase()
          );
        });
    }
  };

  const getEvent = (slug, isUpdate = false) => {
    API.get("events/getEventByEventOrganizer/" + slug, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data)
        if (match.params.slug && !isUpdate) setDataEvent(res.data.data);
        else setEvent(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const setDataEvent = (data) => {
    setEventTypeId(data.eventType._id);
    setImage(data.image.url);
    setTitle(data.title);
    setEventContextId(data.eventContext._id);
    setCost(data.cost);
    setPaidStatus(data.paidStatus);
    setLimitedTicketStatus(data.limitedTicketStatus);
    setDescription(data.description);
    let temp = [];
    data.benefitIds.forEach((element) => {
      temp.push(element._id);
    });
    setBenefitIds(temp);
    temp = [];
    data.preconditionIds.forEach((element) => {
      temp.push(element._id);
    });
    setPreconditionIds(temp);
    temp = [];
    data.preconditionIds.forEach((element) => {
      temp.push(element._id);
    });
    setPreconditionIds(temp);
    temp = [];
    data.languageIds.forEach((element) => {
      temp.push(element._id);
    });
    setLanguageIds(temp);
    setPrivateEvent(data.privateEvent);
    setEventMedia(data.location);
    setAccessEventLink(data.accessEventLink);
    setGroupLink(data.groupLink);
    setStartDate(moment(data.startDate, moment.defaultFormat).toDate());
    setEndDate(moment(data.endDate, moment.defaultFormat).toDate());
    setDeadlineRegistration(
      moment(data.deadlineRegistration, moment.defaultFormat).toDate()
    );
    setDeadlineAttendance(
      moment(data.deadlineAttendance, moment.defaultFormat).toDate()
    );
    setCityId(data.city.length > 0 ? data.city[0]._id : "");
    setAddress(data.address);
    if (data.longitude && data.latitude) {
      setLongitude(parseFloat(data.longitude));
      setLatitude(parseFloat(data.latitude));
    }
    setCertifiedEvent(data.certifiedEvent);
    setHaveCertificateTemplate(data.haveCertificateTemplate);
    setContact(data.contact);
    if (data.haveCertificateTemplate)
      setCertificateFile(data.certificateFile.url);
  };

  const onNext = (status) => {
    if (step === 1) {
      if (eventTypeId === "")
        return showToast("Silakan pilih jenis event", "error");
      else if (typeof image[0] !== "object" && !match.params.slug)
        return showToast("Silakan masukkan poster event", "error");
      else if (title === "")
        return showToast("Silakan masukkan judul event", "error");
    } else if (step === 2) {
      if (eventContextId === "")
        return showToast("Silakan pilih konteks event", "error");
      else if (description === "")
        return showToast("Silakan masukkan deskripsi event", "error");
    } else if (step === 3) {
      if (languageIds.length === 0)
        return showToast("Silakan pilih bahasa event", "error");
      else if (preconditionIds.length === 0)
        return showToast("Silakan pilih prasyarat event", "error");
      else if (benefitIds.length === 0)
        return showToast("Silakan pilih benefit event", "error");
    } else if (step === 4) {
      if (!startDate || !endDate)
        return showToast("Silakan masukkan tanggal event", "error");
      else if (!deadlineRegistration)
        return showToast(
          "Silakan masukkan deadline pendaftaran event",
          "error"
        );
      else if (!deadlineAttendance)
        return showToast("Silakan masukkan deadline absensi event", "error");
    }

    if (step < 5) {
      setStep(step + 1);
    } else {
      if (status === "show") setIsOpen(true);
      else onCreateEvent(status);
    }
  };

  if (!EO._id) return <Redirect from="*" to="/register-event-organizer" />;

  return (
    <Container className="mt-6 pt-6 px-lg-8">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          {step === 6 ? (
            <div className="text-center text-muted mb-4">
              <h1>Selamat</h1>
              <div>Kamu sudah berhasil membuat event di HiEvents</div>
            </div>
          ) : (
            <div className="text-center text-muted mb-4">
              <h2>
                Buat Event Kamu{" "}
                <Badge color="success" pill>
                  {step}
                </Badge>
              </h2>
              {/* <div>Silahkan buat event kamu</div> */}
            </div>
          )}

          <Progress className="mb-4" value={step === 1 ? 20 : step * 20} />

          <Form role="form">
            {step === 1 ? (
              <StepI
                props={{
                  eventTypeId,
                  setEventTypeId,
                  image,
                  setImage,
                  title,
                  setTitle,
                  match,
                }}
              />
            ) : step === 2 ? (
              <StepII
                props={{
                  eventContextId,
                  setEventContextId,
                  cost,
                  setCost,
                  paidStatus,
                  setPaidStatus,
                  limitedTicketStatus,
                  setLimitedTicketStatus,
                  maximumTicket,
                  setMaximumTicket,
                  description,
                  setDescription,
                }}
              />
            ) : step === 3 ? (
              <StepIII
                props={{
                  benefitIds,
                  setBenefitIds,
                  preconditionIds,
                  setPreconditionIds,
                  privateEvent,
                  setPrivateEvent,
                  languageIds,
                  setLanguageIds,
                }}
              />
            ) : step === 4 ? (
              <StepIV
                props={{
                  eventMedia,
                  setEventMedia,
                  accessEventLink,
                  setAccessEventLink,
                  groupLink,
                  setGroupLink,
                  cityId,
                  setCityId,
                  startDate,
                  setStartDate,
                  endDate,
                  setEndDate,
                  deadlineRegistration,
                  setDeadlineRegistration,
                  deadlineAttendance,
                  setDeadlineAttendance,
                  address,
                  setAddress,
                  longitude,
                  setLongitude,
                  latitude,
                  setLatitude,
                }}
              />
            ) : step === 5 ? (
              <StepV
                props={{
                  certifiedEvent,
                  setCertifiedEvent,
                  haveCertificateTemplate,
                  setHaveCertificateTemplate,
                  certificateFile,
                  setCertificateFile,
                  contact,
                  setContact,
                  match,
                }}
              />
            ) : (
              <div className="d-flex justify-content-center">
                {event._id ? (
                  <Event item={event} xl="10" lg="10" md="10" eo={EO} />
                ) : (
                  <SuccessImage />
                )}
              </div>
            )}

            {step === 6 ? (
              <div className="text-center">
                <Button
                  onClick={() => navigate.push("/event/" + event.slug + "=")}
                  className="m-0 mt-4 w-50 text-white bg-hievent"
                  color="bg-hievent"
                  type="button"
                >
                  Lihat Event Saya
                </Button>
              </div>
            ) : (
              <div className="text-center">
                <div className="mt-4 d-flex flex-row">
                  {step !== 1 && step <= 5 ? (
                    <Button
                      onClick={() => setStep(step - 1)}
                      className="w-100"
                      color="primary"
                      type="button"
                    >
                      Kembali
                    </Button>
                  ) : null}
                  {step === 5 ? (
                    <Button
                      disabled={loading}
                      onClick={() => onNext("draft")}
                      className="w-100"
                      color="success"
                      type="button"
                    >
                      {loading ? <Spinner size="sm" /> : null} {"Simpan Draft"}
                    </Button>
                  ) : null}
                  <Button
                    disabled={loading}
                    onClick={() => onNext("show")}
                    className="w-100 text-white bg-hievent"
                    color="bg-hievent"
                    type="button"
                  >
                    {loading ? <Spinner size="sm" /> : null}{" "}
                    {step === 5 ? "Buat Event" : "Berikutnya"}
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </CardBody>
      </Card>

      <Modal
        className="modal-dialog-centered modal-info"
        contentClassName="bg-gradient-purple"
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      >
        <div className="modal-header p-0 px-3 pt-3">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body p-0">
          <div className="text-center p-0 px-3">
            <h2 className="mb-0 text-white">Buat Event</h2>
            <h4 className="text-white m-0 mt-4">
              Setelah berhasil membuat event, Anda tidak dapat mengubah data
              event. Silakan pastikan data event sudah benar.
            </h4>
            {/* <div className="d-flex align-items-center justify-content-center py-4">
                            <img className="result-image" src={img} alt="hievents" />
                        </div> */}
          </div>
        </div>
        <div className="modal-footer mt-3">
          <Button
            onClick={() => (setIsOpen(!isOpen), onCreateEvent("show"))}
            className="btn-white"
            color="default"
            type="button"
          >
            Ya, saya sudah pastikan benar
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            Batal, saya ingin cek lagi
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    EO: state.eoReducer.eoState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_EO: (value) => dispatch({ type: "SET_EO", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
