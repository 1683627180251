import { Link, useHistory } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Button,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
// import Notifikasi from "components/Dropdowns/Notifikasi";
import Profil from "components/Dropdowns/Profil";
import Private from "components/Modals/Private";
import Avatar from "react-avatar";
import { UseAuth } from "routes/useAuth";
import { useEffect, useState } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import login from "../../assets/img/theme/login-white.svg";
import Notifikasi from "components/Dropdowns/Notifikasi";

const UserNavbar = ({ USER, EO, SET_USER, SET_EO }) => {
  const [notifications, setNotifications] = useState([]);
  const navigation = useHistory();
  const useAuth = UseAuth();

  useEffect(() => {
    if (useAuth.auth) {
      getProfile();
      getEventOrganizer();
      getNotifications();
    }
  }, []);

  const getProfile = () => {
    API.get("users/getProfile", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    }).then((res) => {
      // console.log(res.data.data)
      SET_USER(res.data.data);
    });
    // .catch(err => {
    //   console.log(err.response.data)
    // })
  };

  const getEventOrganizer = () => {
    API.get("eventOrganizers/getEventOrganizer", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    }).then((res) => {
      // console.log(res.data.data)
      SET_EO(res.data.data);
    });
    // .catch(err => {
    //   console.log(err.response.data)
    // })
  };

  const getNotifications = () => {
    API.get("/notifications/getNotifications", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        console.log("Notifikasi : ", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let imgUser = login;
  if (useAuth.auth) {
    if (USER._id) {
      if (USER.photo) {
        imgUser = USER.photo.url;
      } else {
        imgUser = null;
      }
    }
  }

  // console.log("Tag Link In DropdownItem", Link);

  return (
    <Navbar
      className="navbar fixed-top navbar-dark d-none d-sm-block bg-white shadow"
      expand="sm"
    >
      <Container fluid>
        <Link className="h4 mb-0 d-block" to="/">
          <Avatar
            className="logo-eo"
            src={require("../../assets/img/brand/favicon.png")}
            size="35"
          />
        </Link>

        {/* Untuk Ukuran Layar md */}
        <Nav className="align-items-center d-lg-none d-sm-flex" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <span className="mb-0 color-hievent text-md font-weight-bold">
                Menu
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="pt-0" to="/cari-event" tag={Link}>
                <span>Cari Event</span>
              </DropdownItem>
              <DropdownItem className="pt-1" to="/event-terdaftar" tag={Link}>
                <span>Event Terdaftar</span>
              </DropdownItem>
              <DropdownItem className="pt-1" to="/ruang-sertifikat" tag={Link}>
                <span>Ruang Sertifikat</span>
              </DropdownItem>
              <Private
                className="ml-3 mt-2"
                style={{ color: "#212529", fontSize: 14 }}
              />
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        {/* Untuk Ukuran Layar lg */}
        <div className="align-items-center d-none d-lg-flex">
          <Link
            className="h4 mb-0 color-hievent d-none d-md-block ml-3"
            to="/cari-event"
          >
            Cari Event
          </Link>
          <Link
            className="h4 mb-0 color-hievent d-none d-md-flex ml-3"
            to="/event-terdaftar"
          >
            Event Terdaftar
          </Link>
          <Link
            className="h4 mb-0 color-hievent d-none d-md-flex ml-3"
            to="/ruang-sertifikat"
          >
            Ruang Sertifikat
          </Link>
          <Private className="h4 mb-0 color-hievent d-none d-md-flex mx-3" />
        </div>

        {/* Untuk Button EO and Favorite */}
        <div className="color-hievent align-items-center d-none d-sm-flex ml-sm-auto">
          {/* <Link
            className="color-hievent d-none d-sm-flex"
            to="/"
          ><i className="ni ni-cart" />
          </Link> */}
          <Link
            className="color-hievent d-none d-sm-flex ml-3"
            to="/event-disimpan"
          >
            <i className="ni ni-favourite-28" />
          </Link>
          {USER._id && <div className="vl ml-3"></div>}
          <UncontrolledDropdown nav className="mt--1">
            <DropdownToggle className="pr-0" nav>
              <Media className="color-hievent align-items-center">
                <i className="ni ni-bell-55" />
              </Media>
            </DropdownToggle>
            <Notifikasi {...notifications} />
          </UncontrolledDropdown>
          {USER._id ? (
            <Button
              onClick={() =>
                !useAuth.auth
                  ? navigation.push("/auth")
                  : EO._id
                  ? navigation.push("/buat-event")
                  : navigation.push("/register-event-organizer")
              }
              className="d-none d-sm-flex ml-3 text-white bg-hievent"
              color="bg-hievent"
              type="button"
            >
              {!EO._id ? "Daftar EO" : "Buat Event"}
            </Button>
          ) : null}
        </div>

        {USER._id && <div className="vl ml-3"></div>}

        {EO._id ? (
          <Link
            className="color-hievent d-none d-sm-flex ml-3"
            to="/event-organizer"
          >
            <Media className="align-items-center">
              <Avatar
                className={EO.logo ? "logo-eo bg-white" : "logo-eo bg-hievent"}
                name={EO.name}
                src={EO.logo.url}
                round={true}
                size="35"
              />
              <Media
                className="ml-2 d-none d-lg-block"
                style={{ maxWidth: 100 }}
              >
                <span className="mb-0 text-sm font-weight-bold color-hievent">
                  {EO.name}
                </span>
              </Media>
            </Media>
          </Link>
        ) : null}

        <Nav
          onClick={() => (!USER._id ? navigation.push("/auth") : null)}
          className="align-items-center d-none d-sm-flex"
          navbar
        >
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media
                className={
                  USER.name
                    ? "align-items-center"
                    : "align-items-center bg-hievent p-2 rounded"
                }
              >
                <Media className="d-none d-lg-block px-2">
                  <span className="mb-0 text-white text-sm font-weight-bold">
                    {USER.name ? "" : "Masuk / Daftar"}
                  </span>
                </Media>
                <Avatar
                  className={
                    USER.photo ? "logo-eo bg-white" : "logo-eo bg-hievent"
                  }
                  name={USER.name ? USER.name : null}
                  round={USER.name ? true : false}
                  src={imgUser}
                  size={USER.name ? "35" : "25"}
                />
                <Media
                  className="ml-2 d-none d-lg-block"
                  style={{ maxWidth: 100 }}
                >
                  <span className="mb-0 color-hievent text-sm font-weight-bold">
                    {USER.name ? USER.name : ""}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            {USER._id ? <Profil /> : null}
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
    EO: state.eoReducer.eoState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
    SET_EO: (value) => dispatch({ type: "SET_EO", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNavbar);
