import Notifikasi from "components/Items/Notifikasi";
import {
    Container,
} from "reactstrap";

const Index = () => {
    return (
        <Container className="pt-8 px-lg-7">
            <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                <div className="d-flex flex-row align-items-center">
                    <i className="fa fa-arrow-left mr-2"/>
                    <span className="font-weight-bold color-hievent">Kembali</span>
                </div>

                <div className="d-flex flex-row align-items-center">
                    <label className="custom-toggle m-0 mr-2">
                        <input type="checkbox" />
                        <span className="custom-toggle-slider rounded-circle" />
                    </label>
                    <span className="font-weight-bold">Baca semua</span>
                </div>
            </div>

            <Notifikasi />
            <Notifikasi />
            <Notifikasi />
            <Notifikasi />

        </Container>
    );
};

export default Index;
