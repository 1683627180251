import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

import "react-datepicker/dist/react-datepicker.css";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/argon-dashboard-react.css";

import { AuthProvider } from "routes/useAuth";
import UserLayout from "layouts/User.js";
import AuthLayout from "layouts/Auth.js";

import { Provider } from "react-redux";
import { createStore } from "redux";
import reducers from "reducers/index";
import MetaTags from "components/Partials/MetaTags";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = createStore(reducers);

// const ClientId = process.env.REACT_APP_clientIdGoogleDev;
const ClientId = process.env.REACT_APP_clientIdGoogleProd;

root.render(
  <>
    <MetaTags
      url="https://hievents.co"
      title="HiEvents"
      image="/favicon.ico"
      description="Find various event ranging from seminars, webinars, competitions, concerts, parties and more"
    />
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <GoogleOAuthProvider clientId={ClientId}>
            <Switch>
              <Route
                path="/auth"
                render={(props) => <AuthLayout {...props} />}
              />
              <Route path="/" render={(props) => <UserLayout {...props} />} />
            </Switch>
          </GoogleOAuthProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </>
);
