import {
    DropdownItem
} from "reactstrap";

const Notifikasi = (props) => {
    return (
        <div className="dropdown-menu-arrow">
            <DropdownItem divider />
            <div className="d-flex flex-row align-items-center py-2">
                <i className="ni ni-check-bold" />
                <div className="d-flex flex-column ml-3 mr-auto">
                    <span>Selamat kamu berhasil mendaftar event</span>
                    <small>Kamu berhasil mendaftar pada event E-sport 2022</small>
                </div>
                <small className="text-gray">19 Sep</small>
            </div>
            {/* <DropdownItem divider /> */}
        </div>
    );
};

export default Notifikasi;
