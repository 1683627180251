import SuccessRegister from "components/Modals/SuccessRegister";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import { showToast } from "./Toast";

const EventRegister = ({ event, accessGroupLink }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const useAuth = UseAuth();
  const navigation = useHistory();

  const registerEvent = () => {
    setLoading(true);
    if (!useAuth.auth) return navigation.push("/auth");

    const data = {
      eventId: event._id,
    };
    API.post("eventRegistrations/registerEvent", data, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data)
        setLoading(false);
        setIsOpen(true);
        showToast(res.data.message, res.data.status.toLowerCase());
      })
      .catch((err) => {
        // console.log(err.response.data)
        setLoading(false);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };
  return (
    <>
      <Button
        onClick={registerEvent}
        className="btn btn-md w-100 text-white bg-hievent"
        color="bg-hievent"
      >
        {loading ? <Spinner size="sm" /> : null} Daftar
      </Button>
      <SuccessRegister props={{ isOpen, setIsOpen, event, accessGroupLink }} />
    </>
  );
};

export default EventRegister;
