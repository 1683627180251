import { googleLogout } from "@react-oauth/google";
import FollowEo from "components/Items/FollowEo";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  CardFooter,
  Badge,
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";

const Profil = ({ FOLLOWING, SET_FOLLOWING, SET_USER, SET_EO }) => {
  const useAuth = UseAuth();

  useEffect(() => {
    getFollowing();
  }, []);

  const getFollowing = () => {
    API.get("follows/getFollowing", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data)
        SET_FOLLOWING(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onLogout = () => {
    API.get("auths/logout", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data)
        SET_USER({});
        SET_EO({});
        SET_FOLLOWING([]);
        useAuth.logout();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <DropdownMenu className="dropdown-menu-arrow" right>
      <DropdownItem to="/profil" tag={Link}>
        <i className="ni ni-circle-08" />
        <span>Profil Saya</span>
      </DropdownItem>
      <DropdownItem className="d-flex flex-row align-items-center px-4">
        <Card className="shadow">
          <CardHeader className="d-flex flex-row align-items-center p-3 justify-content-center">
            <i className="fa fa-users fa-lg" />
            <span className="mx-2">Event Organizer yang diikuti</span>
            <Badge color="primary" pill>
              <span className="text-sm text-primary font-weight-bold">
                {FOLLOWING.length}
              </span>
            </Badge>
          </CardHeader>
          <CardBody className="p-3">
            {FOLLOWING.length === 0 ? (
              <span className="m-4 text-sm font-weight-bold">
                Belum ada Event Organizer yang diikuti
              </span>
            ) : null}

            {FOLLOWING.map((element, index) => {
              return index < 3 ? (
                <FollowEo item={element} key={element._id} />
              ) : null;
            })}
          </CardBody>
          {FOLLOWING.length > 0 ? (
            <CardFooter
              className="d-flex rounded-bottom border-0 p-2 align-items-center bg-gradient-primary"
              to="/profil"
              tag={Link}
            >
              <span className="w-100 text-center text-white">Lihat Semua</span>
            </CardFooter>
          ) : null}
        </Card>
      </DropdownItem>
      <DropdownItem to="/admin/user-profile" tag={Link}>
        <i className="fa fa-question-circle" />
        <span>Contact Support</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        onClick={() => {
          onLogout();
          googleLogout();
        }}
      >
        <i className="ni ni-user-run" />
        <span>Logout</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const mapStateToProps = (state) => {
  return {
    FOLLOWING: state.followingReducer.followingState,
    USER: state.userReducer.userState,
    EO: state.eoReducer.eoState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_FOLLOWING: (value) => dispatch({ type: "SET_FOLLOWING", value: value }),
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
    SET_EO: (value) => dispatch({ type: "SET_EO", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profil);
