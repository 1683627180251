import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import ButtonData from "./Atoms/ButtonData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import API from "utils/API";

const EventCategory = () => {
  const [eventTypes, setEventTypes] = useState([]);

  useEffect(() => {
    getEventTypes();
  }, []);

  const getEventTypes = () => {
    API.get("eventTypes/getEventTypes", {})
      .then((res) => {
        console.log("Event Kategori : ", res.data.data);

        const updatedEventTypes = res.data.data.map((eventType, index) => ({
          ...eventType,
          icon: ButtonData[index]?.icon,
          color: ButtonData[index]?.color,
        }));
        setEventTypes(updatedEventTypes);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // console.log("Event Kategori : ", eventTypes);

  return (
    <div
      style={{
        width: "100%",
        padding: "10px 20px",
      }}
    >
      <div
        className="row"
        style={{
          padding: "10px",
          borderradius: "5px",
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "20px",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        {Array.isArray(eventTypes) && eventTypes.length > 0
          ? eventTypes.map(
              (item, index) =>
                index < 7 && (
                  <Link
                    to={{
                      pathname: "/event-kategori/" + item.slug,
                      state: item._id,
                    }}
                    key={index}
                    style={{
                      width: "fit-content",
                      textAlign: "center",
                      color: "black",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: item.color,
                      }}
                    >
                      {item.icon}
                    </Button>
                    {item.name}
                  </Link>
                )
            )
          : null}
      </div>
    </div>
  );
};

export default EventCategory;
