import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import { Badge, Card, Col, Media, Row, Button } from "reactstrap";
import { capitalizeFirstLetter } from "utils/helpers";
import "./event-terdaftar.css";
import moment from "moment";
import index from "views/event-tertutup";

const EventSaya = ({ item, xl, lg, md, tab }) => {
  const event = item.event;
  const navigate = useHistory();
  const codeSlug = event.privateEvent ? event.generatedEventCode : event.slug;

  console.log("event", item);

  return (
    <Col
      key={item._id}
      onClick={() => {
        index === 1
          ? navigate.push("/event-terdaftar/" + codeSlug)
          : navigate.push(`/event-selesai/${codeSlug}/${item.status}`);
      }}
      className="mb-3"
      xl={xl}
      lg={lg}
      md={md}
    >
      <Card style={{ cursor: "pointer" }} className="row-ticket shadow">
        <div className="card-ticket fl-left">
          <div className="date d-none d-lg-table-cell">
            <time>
              <span>{moment(event.startDate).format("DD")}</span>
              <span>{moment(event.startDate).format("MMM")}</span>
            </time>
          </div>
          <div className="card-ticket-cont p-3">
            <Row style={{ display: "flex" }}>
              <Col
                lg="5"
                className="align-items-center justify-content-center"
                style={{ minHeight: 200, maxHeight: 340 }}
              >
                <img
                  className="mt-1 img-fluid w-100 h-100 rounded-lg"
                  src={event?.image.url}
                  alt="hievents"
                />
              </Col>
              <Col lg="7" className="mx-0 mx-lg--3 mt-3 mt-lg-2">
                <Media className="align-items-center mb-1">
                  <Avatar
                    className="logo-eo"
                    name={event.eventOrganizer?.name}
                    src={event.eventOrganizer?.logo?.url}
                    round={true}
                    size={45}
                  />
                  <Media className="ml-2">
                    <span className="mb-0 text-sm text-success font-weight-bold">
                      {event.eventOrganizer?.name}
                    </span>
                  </Media>
                </Media>
                <h4 className="m-0 title">{event.title}</h4>
                <h4 className="text-success m-0">
                  {capitalizeFirstLetter(event.paidStatus)}
                </h4>
                <Row className="my-2 d-flex d-lg-none">
                  <Col xs="6">
                    <small className="text-sm">Mulai Event</small>
                  </Col>
                  <Col xs="6">
                    <small className="text-sm">
                      {moment(event?.startDate).format("DD MMM YYYY HH:mm")}
                    </small>
                  </Col>
                </Row>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
                <div className="d-flex flex-row flex-wrap">
                  {event.benefitIds?.map((element, index) => {
                    return index <= 5 ? (
                      <Badge
                        key={element._id}
                        color={
                          index % 2 === 0
                            ? "success ml-1 mt-2"
                            : "info ml-1 mt-2"
                        }
                        pill
                      >
                        {element.name}
                      </Badge>
                    ) : null;
                  })}
                </div>

                {/* Event Terdaftar */}
                {tab === 1 && (
                  <Button
                    className="mt-3"
                    style={{
                      width: "100%",
                      display: "block",
                      background: "#62C4EF",
                      color: "white",
                      outline: "none",
                    }}
                    disabled={moment(event?.startDate).isBefore(
                      moment(new Date())
                    )}
                  >
                    Gabung Event
                  </Button>
                )}

                {/* Event Selesai */}
                {tab === 2 && (
                  <Button
                    className="mt-3"
                    style={{
                      width: "100%",
                      display: "block",
                      background:
                        item.status === "attended" ? "#5BE462" : "#766A6A",
                      color: "white",
                      outline: "none",
                    }}
                    disabled
                  >
                    {item.status === "attended" ? "Hadir" : "Tidak Hadir"}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default EventSaya;
